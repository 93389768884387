import axios from 'axios';

const AUTH_TOKEN = '_outvote_web__token';

const setAxiosHeaders = ({ token }) => {
  axios.defaults.headers.common.Authorization = token;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post.accept = 'application/json';
};

function storeToken(token) {
  localStorage.setItem(AUTH_TOKEN, token);
  setAxiosHeaders({ token });
}

function removeToken() {
  localStorage.removeItem(AUTH_TOKEN);
  delete axios.defaults.headers.common.Authorization;
}

function getToken() {
  return localStorage.getItem(AUTH_TOKEN);
}

// TODO: Make this work with current setup
function maybeGetToken() {
  // Get the existing token from local storage if there is one
  const token = getToken();
  setAxiosHeaders({ token });
  return token;
}

function isAuthenticated() {
  return !!getToken();
}

// locale is used to persist user's locale settings across different sessions
function setSessionLocale(locale) {
  localStorage.setItem('locale', locale);
}

function getSessionLocale() {
  return localStorage.getItem('locale');
}

function setRedirectAfterLoginURL(url) {
  localStorage.setItem('redirectAfterLogin', url);
}

function getRedirectAfterLoginURL() {
  return localStorage.getItem('redirectAfterLogin');
}

function getAndClearRedirectAfterLoginURL() {
  const url = localStorage.getItem('redirectAfterLogin');
  localStorage.removeItem('redirectAfterLogin');

  return url;
}

function setCampaignJoinModal() {
  localStorage.setItem('showCampaignJoinModal', true);
}

function getCampaignJoinModal() {
  return localStorage.getItem('showCampaignJoinModal');
}

function clearCampaignJoinModal() {
  localStorage.removeItem('showCampaignJoinModal');
}

function setTeamJoinModal(code) {
  localStorage.setItem('showTeamJoinModal', code);
}

function getTeamJoinModal() {
  return localStorage.getItem('showTeamJoinModal');
}

function clearTeamJoinModal() {
  localStorage.removeItem('showTeamJoinModal');
}

function setOnboardingModal() {
  localStorage.setItem('showOnboardingModal', true);
}

function getOnboardingModal() {
  return localStorage.getItem('showOnboardingModal');
}

function clearOnboardingModal() {
  localStorage.removeItem('showOnboardingModal');
}

export {
  getToken,
  isAuthenticated,
  maybeGetToken,
  storeToken,
  removeToken,
  // Locale exports
  setSessionLocale,
  getSessionLocale,
  // campaign and team utils
  setCampaignJoinModal,
  getCampaignJoinModal,
  clearCampaignJoinModal,
  setTeamJoinModal,
  getTeamJoinModal,
  clearTeamJoinModal,
  // onboarding utils
  setOnboardingModal,
  getOnboardingModal,
  clearOnboardingModal,
  // redirect utils
  setRedirectAfterLoginURL,
  getRedirectAfterLoginURL,
  getAndClearRedirectAfterLoginURL,
};
